import { svgIcon } from "@bitwarden/components";

export const PasswordManagerLogo = svgIcon`
<?xml version="1.0" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20010904//EN"
 "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="180" viewBox="0 0 568.000000 86.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,86.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M285 841 c-148 -42 -242 -126 -273 -244 l-11 -38 73 -54 c40 -29 117
-79 172 -109 54 -31 90 -56 79 -56 -34 0 -194 50 -257 79 l-59 29 6 -32 c12
-56 59 -140 86 -153 14 -7 74 -21 132 -30 59 -10 107 -21 107 -25 0 -5 -45 -8
-100 -8 -55 0 -100 -4 -100 -8 0 -29 121 -104 219 -136 72 -24 231 -57 240
-50 2 2 -8 13 -22 25 -15 11 -39 42 -55 67 -25 44 -27 55 -30 188 l-4 142 -39
17 c-53 24 -122 100 -144 158 -24 62 -16 142 20 203 15 25 25 47 23 49 -1 1
-30 -5 -63 -14z"/>
<path d="M1272 852 c50 -94 60 -126 56 -180 -2 -32 -12 -74 -22 -94 -23 -48
-88 -111 -137 -133 l-39 -18 0 -131 c0 -148 -11 -184 -74 -254 -26 -29 -32
-39 -19 -37 10 2 50 9 88 16 87 15 194 51 251 84 52 30 104 74 104 86 0 5 -45
9 -100 9 -55 0 -100 4 -100 8 0 4 39 14 88 21 164 23 168 25 202 95 16 33 33
75 36 92 l6 31 -59 -28 c-62 -30 -222 -79 -257 -79 -12 0 21 23 79 56 103 58
212 131 233 157 10 12 8 26 -8 70 -27 72 -85 136 -157 174 -55 30 -178 69
-171 55z"/>
<path d="M775 680 c-41 -9 -66 -23 -95 -55 -22 -23 -26 -38 -30 -113 l-5 -87
-30 -3 c-16 -2 -36 -8 -42 -14 -16 -13 -17 -172 -2 -232 52 -208 390 -224 473
-24 21 49 23 240 3 257 -8 6 -75 12 -168 13 l-154 3 0 73 c0 68 2 76 28 98 39
33 81 31 118 -5 22 -23 29 -39 29 -69 0 -38 2 -40 35 -44 40 -5 47 12 18 44
-15 17 -15 18 0 18 23 0 22 35 -3 75 -29 47 -115 79 -175 65z m75 -350 c24
-24 24 -30 6 -55 -12 -16 -12 -25 0 -58 25 -69 22 -74 -46 -76 -67 -1 -68 0
-46 71 10 36 11 48 1 62 -17 22 -8 51 18 64 32 16 45 14 67 -8z"/>
<path d="M1920 590 c0 -80 3 -100 14 -100 10 0 16 13 18 38 3 34 6 37 35 40
34 3 63 33 63 64 0 33 -36 58 -85 58 l-45 0 0 -100z m85 40 c0 -18 -6 -26 -22
-28 -20 -3 -23 1 -23 28 0 27 3 31 23 28 16 -2 22 -10 22 -28z"/>
<path d="M2076 608 c-10 -46 -20 -91 -22 -100 -4 -12 0 -18 10 -18 9 0 16 5
16 10 0 6 14 10 30 10 17 0 30 -4 30 -10 0 -5 8 -10 19 -10 17 0 17 3 -2 100
-18 91 -22 100 -42 100 -20 0 -24 -9 -39 -82z m53 -50 c1 -10 -6 -18 -14 -18
-16 0 -22 60 -8 74 8 8 20 -24 22 -56z"/>
<path d="M2205 670 c-26 -29 -10 -69 38 -95 41 -22 46 -48 10 -53 -14 -2 -23
3 -28 17 -8 25 -23 27 -32 6 -8 -22 25 -55 54 -55 41 0 63 18 63 50 0 23 -7
35 -32 50 -48 28 -58 38 -52 55 10 24 43 18 54 -11 12 -31 36 -26 32 7 -5 49
-73 67 -107 29z"/>
<path d="M2358 679 c-21 -12 -24 -63 -5 -79 6 -6 27 -20 45 -31 36 -21 38 -42
4 -47 -13 -2 -25 4 -32 17 -14 26 -30 27 -30 2 0 -47 71 -69 104 -32 27 30 16
62 -29 88 -46 26 -55 58 -15 58 18 0 26 -6 28 -22 4 -31 32 -30 32 0 0 46 -58
72 -102 46z"/>
<path d="M2483 668 c3 -13 11 -52 17 -89 6 -36 15 -71 20 -78 14 -24 39 -4 42
35 4 48 28 59 28 12 0 -18 4 -39 9 -47 18 -28 35 -3 52 75 22 98 23 114 5 114
-13 0 -26 -41 -26 -86 0 -26 -18 -42 -24 -22 -3 7 -7 34 -11 61 -9 67 -32 63
-40 -8 -12 -89 -24 -83 -40 18 -4 24 -11 37 -22 37 -11 0 -14 -6 -10 -22z"/>
<path d="M2706 668 c-10 -14 -16 -44 -16 -78 0 -71 19 -100 65 -100 46 0 65
29 65 100 0 71 -19 100 -65 100 -24 0 -39 -7 -49 -22z m69 -78 c0 -58 -2 -65
-20 -65 -17 0 -20 8 -23 54 -4 65 2 84 25 79 15 -3 18 -14 18 -68z"/>
<path d="M2850 589 c0 -90 2 -100 18 -97 13 2 17 14 18 43 0 30 5 41 18 43 27
6 46 -17 46 -54 0 -24 5 -34 15 -34 19 0 21 70 3 88 -9 9 -9 15 0 24 19 19 14
55 -10 72 -12 9 -42 16 -65 16 l-43 0 0 -101z m90 56 c16 -19 4 -35 -26 -35
-19 0 -24 5 -24 25 0 29 29 35 50 10z"/>
<path d="M3020 590 l0 -100 38 0 c21 0 48 7 60 16 19 13 22 24 22 84 0 60 -3
71 -22 84 -12 9 -39 16 -60 16 l-38 0 0 -100z m78 58 c16 -16 16 -100 0 -116
-7 -7 -20 -12 -30 -12 -16 0 -18 9 -18 70 0 61 2 70 18 70 10 0 23 -5 30 -12z"/>
<path d="M3230 590 c0 -82 3 -100 15 -100 12 0 15 14 15 70 0 71 7 83 31 54
11 -14 13 -14 21 0 18 33 28 14 28 -55 0 -61 2 -70 17 -67 9 2 16 6 15 11 -1
4 -2 48 -2 97 0 76 -3 90 -16 90 -9 0 -24 -10 -34 -22 l-19 -23 -16 23 c-9 12
-25 22 -36 22 -18 0 -19 -8 -19 -100z"/>
<path d="M3426 608 c-9 -46 -19 -91 -22 -100 -4 -12 0 -18 10 -18 9 0 16 5 16
10 0 6 13 10 29 10 17 0 33 -4 36 -10 3 -5 12 -10 19 -10 10 0 9 22 -7 100
-18 90 -22 100 -42 100 -19 0 -24 -10 -39 -82z m53 -45 c1 -14 -5 -23 -14 -23
-16 0 -20 35 -8 66 6 16 7 16 14 -2 4 -10 8 -29 8 -41z"/>
<path d="M3560 590 c0 -81 3 -100 14 -100 11 0 16 15 18 53 l3 52 30 -52 c45
-79 55 -71 55 47 0 82 -3 100 -15 100 -12 0 -15 -13 -15 -56 0 -65 -11 -64
-41 7 -35 80 -49 66 -49 -51z"/>
<path d="M3745 678 c-7 -26 -35 -161 -35 -174 0 -8 7 -14 15 -14 8 0 15 5 15
10 0 6 14 10 30 10 17 0 30 -4 30 -10 0 -5 7 -10 15 -10 8 0 15 2 15 4 0 5
-27 139 -35 174 -6 24 -43 31 -50 10z m35 -82 c0 -13 3 -31 6 -40 4 -12 0 -16
-16 -16 -18 0 -21 4 -16 23 3 12 6 30 6 40 0 9 5 17 10 17 6 0 10 -11 10 -24z"/>
<path d="M3866 668 c-10 -14 -16 -44 -16 -78 0 -68 19 -100 61 -100 38 0 69
32 69 70 0 27 -3 30 -30 30 -30 0 -43 -24 -18 -32 18 -6 1 -38 -21 -38 -23 0
-31 19 -31 70 0 21 3 45 6 54 9 23 39 20 57 -6 12 -18 18 -20 28 -11 9 10 9
17 -5 37 -22 34 -78 36 -100 4z"/>
<path d="M4010 590 l0 -100 50 0 c38 0 50 4 50 15 0 10 -11 15 -35 15 -32 0
-35 2 -35 30 0 28 3 30 35 30 24 0 35 5 35 15 0 10 -11 15 -35 15 -31 0 -35 3
-35 25 0 22 4 25 35 25 24 0 35 5 35 15 0 11 -12 15 -50 15 l-50 0 0 -100z"/>
<path d="M4150 590 c0 -95 1 -100 21 -100 19 0 20 5 17 70 -3 71 4 84 28 53
12 -17 13 -17 19 0 4 9 13 17 21 17 11 0 14 -17 14 -70 0 -56 3 -70 15 -70 12
0 15 18 15 100 0 87 -2 100 -17 100 -9 0 -26 -9 -38 -20 l-22 -20 -18 20 c-10
11 -26 20 -37 20 -16 0 -18 -9 -18 -100z"/>
<path d="M4340 590 l0 -100 50 0 c38 0 50 4 50 15 0 10 -11 15 -35 15 -32 0
-35 2 -35 30 0 28 3 30 35 30 24 0 35 5 35 15 0 10 -11 15 -35 15 -31 0 -35 3
-35 25 0 22 4 25 35 25 24 0 35 5 35 15 0 11 -12 15 -50 15 l-50 0 0 -100z"/>
<path d="M4480 590 c0 -82 3 -100 15 -100 11 0 15 12 15 51 0 42 2 49 13 40 8
-6 22 -29 32 -51 32 -70 45 -53 45 60 0 81 -3 100 -15 100 -10 0 -15 -15 -17
-57 l-3 -58 -30 58 c-44 85 -55 76 -55 -43z"/>
<path d="M4630 675 c0 -8 9 -15 20 -15 18 0 20 -7 20 -85 0 -68 3 -85 15 -85
11 0 15 18 17 83 3 76 5 82 26 85 12 2 22 10 22 18 0 11 -15 14 -60 14 -47 0
-60 -3 -60 -15z"/>
<path d="M4836 671 c-26 -29 -16 -62 29 -92 45 -31 51 -52 17 -57 -17 -3 -25
2 -29 17 -7 26 -33 28 -33 2 0 -47 71 -69 104 -32 27 30 16 62 -29 88 -46 26
-55 58 -15 58 18 0 26 -6 28 -22 4 -31 32 -30 32 0 0 51 -70 76 -104 38z"/>
<path d="M4960 683 c0 -5 9 -32 20 -61 11 -29 20 -70 20 -92 0 -29 4 -40 15
-40 11 0 15 11 15 40 0 21 9 62 20 90 24 61 25 70 5 70 -8 0 -17 -14 -21 -35
-8 -43 -25 -41 -38 3 -5 18 -15 32 -22 32 -8 0 -14 -3 -14 -7z"/>
<path d="M5107 672 c-10 -10 -17 -28 -17 -40 0 -21 37 -57 71 -67 27 -9 20
-39 -9 -43 -17 -3 -25 2 -29 17 -7 26 -33 28 -33 3 0 -42 66 -68 102 -39 39
30 26 67 -33 97 -44 23 -50 53 -11 58 16 3 25 -4 32 -23 12 -32 36 -27 32 6
-5 47 -73 67 -105 31z"/>
<path d="M5230 675 c0 -9 9 -15 25 -15 25 0 25 -1 25 -85 0 -69 3 -85 15 -85
12 0 15 16 15 85 0 84 0 85 25 85 16 0 25 6 25 15 0 12 -14 15 -65 15 -51 0
-65 -3 -65 -15z"/>
<path d="M5380 590 l0 -100 55 0 c41 0 55 4 55 14 0 10 -13 16 -37 18 -33 3
-38 6 -38 28 0 22 5 25 38 28 47 4 45 25 -4 32 -26 4 -34 10 -34 25 0 16 8 21
38 23 24 2 37 8 37 18 0 10 -14 14 -55 14 l-55 0 0 -100z"/>
<path d="M5530 590 c0 -82 3 -100 15 -100 12 0 15 15 15 78 1 76 1 77 19 55
16 -20 21 -21 31 -8 23 28 30 15 30 -55 0 -56 3 -70 15 -70 12 0 15 18 15 100
0 92 -1 100 -19 100 -11 0 -27 -10 -36 -22 l-16 -23 -16 23 c-8 12 -24 22 -34
22 -17 0 -19 -9 -19 -100z"/>
<path d="M2139 356 c-17 -8 -24 -20 -24 -41 0 -24 7 -34 37 -50 40 -21 49 -42
22 -52 -11 -4 -23 2 -35 18 -16 21 -18 22 -24 6 -10 -25 13 -47 50 -47 64 0
72 49 15 88 -38 25 -46 55 -17 60 12 3 20 -4 24 -17 7 -26 33 -28 33 -2 0 34
-45 55 -81 37z"/>
<path d="M3215 358 c-30 -17 -35 -28 -35 -85 0 -59 17 -83 59 -83 31 0 51 16
51 42 0 24 -8 23 -29 -4 -30 -37 -51 -18 -51 47 0 65 21 84 51 47 21 -27 29
-28 29 -3 0 11 -10 27 -22 35 -26 19 -27 19 -53 4z"/>
<path d="M4244 345 c-14 -14 -22 -30 -19 -35 10 -16 22 -12 28 10 6 23 29 26
45 7 9 -10 2 -25 -28 -61 -55 -66 -53 -81 10 -81 33 0 50 4 50 13 0 7 -13 12
-30 12 -37 0 -37 6 0 50 36 41 38 73 8 94 -30 21 -35 20 -64 -9z"/>
<path d="M4640 357 c-22 -11 -25 -20 -28 -76 -4 -75 7 -91 59 -91 47 0 59 18
59 87 0 73 -37 106 -90 80z m44 -23 c12 -5 16 -20 16 -59 0 -45 -3 -55 -20
-60 -32 -10 -40 2 -40 59 0 65 7 74 44 60z"/>
<path d="M5022 354 c-12 -8 -22 -24 -22 -35 0 -25 16 -24 30 1 6 11 17 20 26
20 31 0 25 -30 -15 -78 -23 -26 -41 -54 -41 -60 0 -8 17 -12 45 -12 25 0 48 0
52 -1 4 0 9 4 11 10 2 7 -11 11 -32 11 -39 0 -45 10 -21 30 31 26 56 70 50 88
-3 11 -17 24 -31 30 -31 15 -25 15 -52 -4z"/>
<path d="M2492 273 c2 -58 7 -88 16 -91 9 -3 12 19 12 87 0 75 -3 91 -15 91
-13 0 -15 -14 -13 -87z"/>
<path d="M2802 275 c3 -92 20 -118 28 -45 l5 45 28 -48 c16 -26 33 -44 38 -41
5 3 9 43 9 90 0 67 -3 84 -14 84 -11 0 -16 -14 -18 -47 l-3 -48 -25 48 c-38
74 -51 63 -48 -38z"/>
<path d="M3570 276 l0 -84 45 -4 c31 -2 45 1 45 9 0 8 -13 13 -31 13 -27 0
-30 3 -27 28 3 23 7 27 31 24 19 -3 27 1 27 12 0 11 -9 16 -30 16 -25 0 -30 4
-30 25 0 21 5 25 30 25 17 0 30 5 30 10 0 6 -20 10 -45 10 l-45 0 0 -84z"/>
<path d="M5418 338 c-30 -45 -57 -106 -51 -112 4 -3 19 -6 34 -6 19 0 28 -6
32 -21 6 -23 27 -17 27 7 0 8 5 14 10 14 6 0 10 7 10 15 0 8 -4 15 -10 15 -5
0 -10 25 -10 55 0 61 -16 73 -42 33z m12 -63 c0 -16 -6 -25 -15 -25 -18 0 -18
5 -5 31 14 26 20 24 20 -6z"/>
</g>
</svg>
`;
