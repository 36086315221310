<router-outlet></router-outlet>
<div class="container my-5 text-muted text-center">
  云网密码管理系统<br />
  {{ "versionNumber" | i18n: version }}
  <br /><br />
  <div class="small">
    醉里挑灯看剑，梦回吹角连营。八百里分麾下灸，五十弦翻塞外声。沙场点秋兵。
    <br />
    马作的卢飞快，弓如霹雳弦惊。了却君王天下事，嬴得生前身后名。可怜白发生！
  </div>
</div>
